@include media(">phone", "<=tablet") {
  // background-color: red !important;

  // hero slider
  #hero-slider {
    .each-slide {
      img {
        height: 630px;
      }

      #content {
        height: 680px;

        #content_text {
          margin-top: 100px;
          text-align: center;

          h1 {
            font-size: 40px;
            margin-bottom: 80px;
          }

          p {
            font-size: 18px;
          }

          button {
            margin-top: 100px;
          }
        }
      }
    }
  }

  // about
  #home_about {
    padding: 20px 15px;

    &::before {
      width: 150px;
      height: 150px;
      top: -50px;
      left: -5px;
    }

    &::after {
      width: 150px;
      height: 150px;
      top: 450px;
      left: 68;
    }

    h2 {
      font-size: 24px;
      margin-bottom: 25px;
    }

    p {
      font-size: 15px;
    }

    section {
      margin-top: 35px;
    }

    li {
      font-size: 18px;
    }

    img {
      width: 100%;
      height: auto;
      margin-top: 30px;
    }
  }

  // different
  #different {
    padding: 50px 0;
    margin: 50px 0;

    border-top-left-radius: 50% 2.5%;
    border-top-right-radius: 50% 2.5%;
    border-bottom-left-radius: 50% 2.5%;
    border-bottom-right-radius: 50% 2.5%;

    h2 {
      font-size: 30px;
      margin: 0;
    }

    .each_different {
      padding: 20px;

      h4 {
        font-size: 20px;
        margin: 0;
        padding: 0 50px;
      }

      p {
        padding: 0 50px;
        font-size: 15px;
      }

      section {
        height: 70px;
        width: 70px;
        margin-bottom: 10px;
      }
    }
  }

  // quiz
  #quiz {
    margin: 25px 0;

    &::before {
      width: 200px;
      height: 200px;
      left: -30px;
      top: 2500px;
    }

    h2 {
      font-size: 24px;
    }

    #quiz_text {
      margin: 50px 0;
      text-align: center;
    }

    img {
      height: auto;
      width: 100%;
    }

    #quiz_image {
      text-align: center;
    }
  }

  // gallery
  #home_gallery {
    padding: 25px 0;
    text-align: left;

    h2 {
      font-size: 30px;
    }

    h3 {
      font-size: 20px;
      margin-top: 50px;
    }

    p {
      font-size: 13px;
    }
  }

  // get app
  #get_app {
    text-align: center;

    h2 {
      font-size: 30px;
      margin-bottom: 15px;
    }

    img {
      width: 100%;
      height: auto;
    }

    #get_app_text {
      padding-left: 20px;
      padding-right: 5px;

      .MuiListItemText-root {
        span {
          font-size: 18px;
        }
      }

      .btn_green {
        margin: 0;
      }
    }
  }

  // video
  #video {
    margin: 50px 0;

    iframe {
      height: 700px;
    }
  }

  // newsletter
  #newsletter {
    h2 {
      font-size: 24px;
      text-align: left;
      margin-bottom: 30px;
    }

    p {
      font-size: 12px;
    }

    #newsletter_form {
      padding: 0 10px;

      form {
        margin-top: 30px;
        text-align: center;

        .MuiInputBase-root {
          margin-bottom: 20px;
        }

        .btn_green {
          margin-top: 20px;
        }
      }
    }
  }

  // footer
  .triangle::after {
    left: 38%;
  }
  #footer {
    background-color: black;
    color: white;
    padding: 20px;
    text-align: center;

    #social_media {
      padding: 20px 0;
    }

    #copy_right {
      font-size: 18px;
      text-align: center;
      padding: 20px 0;
    }
  }

  // bact to top
  #back_to_top {
    bottom: 50px;
    right: 20px;
    width: 40px;
    height: 40px;
  }

  #T_and_C {
    iframe {
      padding: 0;
    }
  }

  // maintenance
  #maintenance {
    padding: 20px;

    #text {
      h1 {
        font-size: 45px;
      }

      p {
        font-size: 16px;
      }

      section {
        margin-top: 30px;
        margin-bottom: 70px;
      }
    }

    #image {
      text-align: center;
      margin-bottom: 50px;

      div {
        margin-top: 30px;

        img {
          width: auto;
          height: auto;
        }
      }
    }
  }
}
