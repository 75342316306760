  #navigation {
    background-color: white;
    box-shadow: none;
    color: black;

    a{
      color: black;
      text-decoration: none;
    }

    .MuiToolbar-root {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      section {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
      }
    }
  }