// fonts
$lato: "Lato", sans-serif;
$poppins: "Poppins", sans-serif;
$permanentMarker: "Permanent Marker", cursive;
$openSans: "Open Sans", sans-serif;
$VarelaRound: "Varela Round", sans-serif;
$singo: "Oswald", sans-serif;
$Montserrat: "Montserrat", sans-serif;

// colors
$site_black: #303030;
$site_green: #249421;
$site_gray: #838383;

.btn_green {
  background: $site_green;
  border-radius: 5px;
  color: white;
  padding: 10px 20px;
  margin-top: 50px;
  font-weight: 400;
  font-size: 32px;
  letter-spacing: 0.02em;

  &:hover {
    background-color: white;
    color: $site_green;
    border: 1px solid $site_green;
  }
}

#back_to_top {
  position: fixed;
  bottom: 50px;
  right: 20px;
  z-index: 99;
  border: none;
  outline: none;
  background-color: red;
  cursor: pointer;
  color: white;
  width: 60px;
  height: 60px;
  border-radius: 50px;
  font-size: 18px;
}

#back_to_top:hover {
  background-color: #555;
}

#T_and_C {
  h1 {
    text-align: center;
  }

  iframe {
    margin: 0;
    padding: 0 0 0 22%;
    border: none;
    width: 100%;
    height: 500px;
    background-color: $site_green;
  }
}

.send {
  color: white;
}
