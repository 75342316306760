#survey {
  text-align: center;
  padding: 50px 20px;
  background-color: rgb(49, 36, 36);
  color: white;
  font-family: $Montserrat;

  #survey_item {
    h3,
    p {
      margin: 0;
    }

    h3 {
      font-weight: 600;
      font-size: 40px;
    }

    p {
      font-weight: 300;
      font-size: 24px;
    }

    img {
      height: 150px;
      width: 150px;
    }
  }

  #survey_btn {
      margin-top: 100px;

    .btn {
      border: 4px solid #249421;
      border-radius: 5px;
      font-weight: 700;
      font-size: 42px;
      color: white;
      padding: 5px 50px;
    }
  }
}
