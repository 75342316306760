#quiz {
    margin: 50px 0;
    font-family: $openSans;

    &::before {
        position: absolute;
        content: '';
        z-index: -1;
        width: 450px;
        height: 450px;
        left: -102px;
        top: 2167px;
        border-radius: 50%;
        background: #F4FDF4;
        background-blend-mode: darken;
    }

    h2 {
        font-weight: 700;
        font-size: 48px;
        color: $site_black;
        margin: 0;
        padding-left: 22.5px;
        padding-right: 22.5px;

        span {
            color: green;
        }
    }

    #quiz_text {
        margin: 100px 0;
        text-align: center;
    }

    img {
        height: auto;
        width: 100%;
    }
}