#maintenance {
  padding: 30px;
  font-family: $VarelaRound;
  height: auto;

  #text {
      margin-top: 30px;
    h1,
    p {
      font-weight: bold;
    }

    h1 {
      font-size: 60px;
      color: #272727;
      margin: 0;
    }

    p {
      font-size: 20px;
      color: #c4c4c4;
    }

    section {
      margin-top: 50px;
      margin-bottom: 150px;
    }
  }

  #image {
    width: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }
}
