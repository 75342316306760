#newsletter {
    width: 90%;
    margin: auto;
    background-color: #FFFFFF;
    border: 2px solid #F6F6F6;
    border-radius: 5px;
    font-family: $lato;
    color: $site_black;

    h2 {
        font-weight: 900;
        font-size: 36px;
        text-align: center;
        margin-bottom: 50px;
    }

    p {
        font-weight: 300;
        font-size: 14px;
        margin: 0;
    }

    #newsletter_form {
        padding: 0 50px;

        form {
            margin-top: 50px;
            text-align: center;

            .MuiInputBase-root {
                margin-bottom: 40px;

                border-radius: 50px;
                border: 1px solid #EEEEEE;
            }

            .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
                border-color: $site_green !important;
            }

            .Mui-focused {
                color: $site_green;
            }

            .btn_green {
                margin-top: 0;
            }
        }
    }

    img {
        width: 100%;
        height: auto;
    }
}