#family_mp {
  padding: 20px;
  font-family: $Montserrat;
  background: #f8f2f2;
  color: #1f1f1f;

  h1,
  h2,
  h3,
  p {
    margin: 0;
  }

  #family_mp_top {
    text-align: center;
    margin-bottom: 50px;

    img {
      height: 300px;
      width: 300px;
    }

    h1 {
      font-weight: 700;
      font-size: 90px;
    }

    h2 {
      font-weight: 300;
      font-size: 32px;
    }
  }

  #family_mp_content {
    text-align: center;

    img {
      height: 150px;
      width: 150px;
    }

    section {
      border: 1px solid #c4c4c4;
      box-sizing: border-box;
      border-radius: 2px;
      margin: 20px;
      padding: 60px 10px;
    }

    aside {
      margin-top: 20px;

      h3 {
        font-weight: 500;
        font-size: 30px;
      }

      p{
        font-weight: 200;
        font-size: 28px;
      }
    }
  }
}
