#home_gallery {
    background: linear-gradient(254.95deg, #260316 5.76%, rgba(32, 15, 3, 0.951695) 46.34%, rgba(1, 4, 18, 0.9) 68.05%, #000000 83.26%);
    margin: 50px 0;
    padding: 50px 0;
    font-family: $lato;
    color: white;
    text-align: center;

    h2 {
        font-size: 48px;
        letter-spacing: 0.04em;
        font-weight: 900;
    }

    h3 {
        font-family: $openSans;
        font-weight: 300;
        font-size: 24px;
        margin-bottom: 0;
        margin-top: 100px;
    }

    p {
        font-family: $poppins;
        color: white;
        font-size: 16px;
        text-align: left;
        display: none;
    }


    .swiper-container {
        width: 100%;
        padding-top: 20px;
        padding-bottom: 50px;
    }

    .swiper-slide {
        background-position: center;
        background-size: cover;
        width: 50%;
        height: auto;
    }

    .swiper-slide img {
        display: block;
        width: 100%;
    }

    .swiper-slide-active {

        p {
            display: block;
        }
    }

    .swiper-pagination {
        .swiper-pagination-bullet {
            background-color: white;
        }
    }

}