/* for desktop */
.whatsapp_float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 120px;
    right: 20px;
    background-color: #25d366;
    color: #FFF;
    border-radius: 50px;
    font-size: 30px;
    box-shadow: 2px 2px 3px #999;
    z-index: 100;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    &:hover{
        background-color: $site_green;
    }
}

/* for mobile */
@media screen and (max-width: 767px) {
    .whatsapp_float {
        width: 40px;
        height: 40px;
        bottom: 100px;
        right: 20px;
        font-size: 22px;
    }
}