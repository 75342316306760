#get_app {
    margin: 100px 0;
    color: $site_black;
    letter-spacing: 0.02em;
    font-family: $lato;

    h2 {
        font-weight: 900;
        font-size: 48px;
        margin: 0;
    }

    img {
        width: 100%;
        height: auto;
    }

    #get_app_text {
        padding-left: 50px;
        padding-right: 20px;

        .MuiListItemIcon-root {
            min-width: 45px;
        }

        li {
            padding-left: 0;
            letter-spacing: 0.03em;
        }

        .MuiListItemText-root {
            span {
                font-size: 20px;
                font-weight: 300;
                margin-top: 3.5px;
            }
        }
    }
}