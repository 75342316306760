#hero_nav {
  font-family: $poppins;
  background-image: url("https://res.cloudinary.com/dsepncuj8/image/upload/v1648451183/Figma%20Wbsite%20Images/ezgif.com-gif-maker_1_FOR_UPLOAD_xm3knq.gif");
  height: 900px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-blend-mode: color-dodge;
  background-color: rgba(0, 0, 0, 0.38);

  @keyframes rotate {
    100% { 
      transform: rotateY(150deg);;
    }
  }

  #nav {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    position: -webkit-sticky;
    position: sticky;
    top: 0px;
    z-index: 1;

    img {
      width: 220px;
      height: 60px;
      // animation: rotate 30s ease-in-out 10s infinite;
    }

    aside {
      a {
        font-weight: 300;
        font-size: 24px;
        color: #ffffff;
        text-decoration: none;
        margin-left: 50px;

        &:hover {
          color: $site_green;
        }
      }

      .btn_green {
        &:hover {
          color: $site_green;
        }
      }
    }
  }

  .nav_scroll {
    background-color: white;

    img {
      width: 230px !important;
      height: 70px !important;
    }

    aside {
      a {
        color: $site_green !important;

        &:hover {
          color: #1f1f1f !important;
        }
      }

      .btn_green {
        color: white !important;

        &:hover {
          color: $site_green !important;
        }
      }
    }
  }

  #hero {
    text-align: center;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(40px);
    border-radius: 10px;
    margin: 50px 250px;
    padding-top: 20px;

    img{
      // animation: rotate 10s ease-in-out infinite;
    }

    .carousel-status,
    .control-dots,
    .thumbs-wrapper {
      display: none;
      visibility: hidden;
    }

    p {
      font-family: $lato;
      font-weight: 700;
      font-size: 20px;
      margin: 0;
    }

    aside {
      margin-top: 0px;

      h1 {
        font-family: $singo;
        font-style: normal;
        font-weight: 600;
        font-size: 90px;
        margin: 0;

        span {
          color: #889a1a;
        }
      }
    }
  }
}

#mobile_nav {
  p {
    margin: 20px 10px;
  }

  a {
    font-weight: 300;
    font-size: 24px;
    color: black;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.MuiDrawer-paper {
  width: 70%;
}
