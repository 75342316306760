#hero-slider {
  .carousel-status,
  .thumbs-wrapper {
    display: none;
    visibility: hidden;
  }

  .each-slide {
    position: relative;
    width: 100%;

    img {
      width: 100% !important;
      height: 650px;
    }

    #content {
      position: absolute;
      bottom: 0;
      color: white;
      width: 100%;
      height: 700px;
      padding: 20px;
      background: linear-gradient(
        263.03deg,
        #000000 22.33%,
        rgba(0, 0, 0, 0.4) 84.02%,
        rgba(0, 0, 0, 0) 95.87%
      );
      transform: matrix(-1, 0, 0, 1, 0, 0);

      #content_text {
        margin-top: 50px;
        transform: rotateY(180deg);
        text-align: left;
        padding-left: 50px;

        h1 {
          font-family: $lato;
          font-weight: 900;
          font-size: 72px;
        }

        p {
          font-family: $poppins;
          font-weight: 200;
          font-size: 30px;
          margin-bottom: 0;
          margin-top: 0;
        }

        button {
          background: rgba(48, 48, 48, 0.85);
          border: 1px solid #eeeeee;
          box-sizing: border-box;
          border-radius: 5px;
          margin-top: 80px;
          padding: 10px 20px;
          color: white;

          &:hover {
            color: black;
            background-color: white;
          }
        }
      }
    }
  }
}
