#get_app_v2 {
  background-image: url("https://res.cloudinary.com/dsepncuj8/image/upload/v1655880438/Figma%20Wbsite%20Images/RECENT%20WEB%20SITE%20IMAGES/Masked_image_ukeaxy.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0 30px 30px;
  font-family: $Montserrat;
  color: #ffffff;

  @keyframes rotate {
    100% { 
      transform: rotateY(150deg);;
    }
  }

  #btns{
    img{
      height: 70px;
      width: 250px;
      transition: transform 2s ease-in-out;

      &:hover{
        transform: scale(1.2);
      }
    }
  }

  h3,
  h1 {
    margin: 0;
  }

  #get_app_content {
    padding-top: 150px;

    h3 {
      font-weight: 300;
      font-size: 40px;
    }

    h1 {
      font-weight: 700;
      font-size: 48px;
    }

    section {
      margin: 50px 0;
    }
  }

  #mobile_phone{
    img{
      // animation: rotate 20s ease-in-out 30s infinite;
    }
  }

  #get_app_options {
    img {
      width: 100px;
      height: 100px;
      // animation: rotate 20s ease-in-out 10s infinite;
    }

    h3 {
      font-weight: 700;
      font-size: 24px;
    }
  }

  button{
    font-weight: 400;
    font-size: 32px;
  }
}
