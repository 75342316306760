@include media(">phone", "<=tablet") {
  // background-color: red !important;

  // hero and nav
  #hero_nav {
    height: 800px;
    background-image: url("https://res.cloudinary.com/dsepncuj8/image/upload/v1655881573/Figma%20Wbsite%20Images/RECENT%20WEB%20SITE%20IMAGES/Webbsite_GIF_NEW_2_1_on44ht.gif");

    #hero {
      margin: 10px;

      p {
        font-size: 16px;
      }

      aside {
        margin-top: 30px;

        h1 {
          font-size: 50px;
        }
      }
    }
  }

  // family
  #family_mp {
    #family_mp_top {
      img {
        height: 200px;
        width: 200px;
      }

      h1 {
        font-size: 30px;
      }

      h2 {
        font-size: 15px;
      }
    }

    #family_mp_content {
      img {
        height: 100px;
        width: 100px;
      }

      section {
        padding: 30px 5px;
      }

      aside {
        margin-top: 10px;

        h3 {
          font-size: 25px;
        }

        p {
          font-size: 20px;
        }
      }
    }
  }

  // get app v2
  #get_app_v2 {
    padding: 0;

    #btns{
      margin-top: 30px;

      img{
        height: 50px;
        width: 150px;
        margin-left: 25px;
      }
    }

    h3,
    h1 {
      margin: 0;
    }

    #get_app_content {
      padding-top: 70px;
      text-align: center;

      h3 {
        font-size: 25px;
      }

      h1 {
        font-size: 15px;
      }

      section {
        margin: 10px 0 50px 0;
      }
    }

    #get_app_options {
      text-align: center;

      img {
        width: 50px;
        height: 50px;
      }

      h3 {
        font-size: 15px;
      }
    }

    #mobile_phone {
      width: 100%;
      margin-left: 50px;

      img {
        width: 100%;
        height: auto;
      }
    }
  }

  // cards
  #cards {
    margin: 0;

    #healthy,
    #ingredients,
    #kitchen {
      height: 700px;
      background-size: cover;
    }

    #healthy {
      background-image: url("https://res.cloudinary.com/dsepncuj8/image/upload/v1655880403/Figma%20Wbsite%20Images/RECENT%20WEB%20SITE%20IMAGES/Jollof_rice_1_xudztp.png");
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      img{
        height: 150px;
        width: 150px;
      }

      h1,
      h3 {
        margin: 0;
        font-size: 17.5px;
      }
    }

    #ingredients {
      margin-top: 0%;

      section {
        margin-top: 30%;
      }

      h3 {
        font-size: 25px;
        color: #ffe197;
      }
    }

    #kitchen {
      margin-top: 0;
      background-image: url("https://res.cloudinary.com/dsepncuj8/image/upload/v1655880399/Figma%20Wbsite%20Images/RECENT%20WEB%20SITE%20IMAGES/iii_ckcuyt.png");

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      section {
        margin-top: 20px;
      }

      h1 {
        font-size: 35px;
      }

      h3 {
        font-size: 22px;
      }
    }
  }

  // footer
  #footer_v2 {
    text-align: center;
    #contact {
      padding-left: 0px;
    }

    .footer_content {
      margin-bottom: 30px;
      h3 {
        font-size: 28px;
      }

      aside {
        a {
          font-size: 25px;
        }
      }
    }

    #lower_footer {
      h1,
      h3 {
        padding: 0;
      }

      h1 {
        font-size: 16px;
        margin-top: 17px;
        padding-left: 5px;
      }

      h3 {
        font-size: 16px;
      }
    }
  }
}
