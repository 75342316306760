#home_about {
    font-family: $lato;
    padding: 50px;
    letter-spacing: 0.02em;
    color: $site_black;
    position: relative;

    &::before {
        position: absolute;
        content: '';
        z-index: -1;
        width: 450px;
        height: 450px;
        top: -250px;
        left: -50px;
        border-radius: 50%;
        background: linear-gradient(234.58deg, rgba(244, 253, 244, 0.12) 9.95%, #D3F8D3 51.88%, #C8F7C8 78.83%);
        background-blend-mode: darken;
    }

    &::after {
        position: absolute;
        content: '';
        z-index: -1;
        width: 450px;
        height: 450px;
        top: 100px;
        left: 66.5%;
        border-radius: 50%;
        background: linear-gradient(235.68deg, rgba(255, 230, 185, 0.19) 9.84%, #FFFDE8 78.54%);
        background-blend-mode: darken;
        overflow: hidden;
    }

    h2 {
        font-weight: 900;
        font-size: 48px;
        margin-bottom: 10px;
    }

    h3, p {
        margin-bottom: 0;
        margin-top: 0;
        font-weight: normal;
        font-size: 20px;
    }

    h3{
        margin-top: 50px;
    }

    section {
        margin-top: 30px;
    }

    li {
        font-weight: 600;
        font-size: 24px;
        letter-spacing: 0.03em;
        margin-top: 10px;
    }

    img {
        width: 100%;
        height: auto;
    }
}